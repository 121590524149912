// body
& {
	font-size: 17px;
}

// container
.container-fluid {
	max-width: 1310px;
}

// headings
h1,
h2 {
	font-style: italic;
	font-family: $font-family-secondary;
}

h1 {
	font-weight: 500;
}

h2 {
	font-weight: 700;
}

strong {
	font-weight: 600;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// link-arrow
a.link-arrow {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	text-decoration: none;

	&::after {
		content: "\f101";
		margin-left: 5px;
		color: $red-light;
		font-size: 14px;
		font-weight: 500;
		line-height: 1;
		font-family: $font-awesome;
		transition: 0.5s;
	}

	&:hover {
		margin-left: 5px;

		&::after {
			color: $red;
		}
	}
}

// default-card
.default-card {
	border-color: $cream;
	border-radius: 6px;
	box-shadow: 0 0 12px rgba($black, 0.15);

	.card-body {
		padding: 20px 15px 40px 15px;

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-title {
				font-weight: 700;
				font-style: italic;
				font-family: $font-family-secondary;
			}

			.card-subtitle {
				order: 1;
				margin-bottom: 0;
				font-size: 17px;
				font-weight: 400;
				font-style: normal;
				font-family: $font-family-primary;
				margin-top: 10px;
			}

			.card-description {
				display: flex;
				flex-direction: column;

				.list-icons {
					order: -1;
					margin: 0.5rem 0;

					.list-item {
						margin: 2px 0;
						font-size: 14px;

						.icon {
							color: $red-light;

							i {
								font-size: 16px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}

		.card-buttons {
			margin-top: 30px;
		}
	}
}

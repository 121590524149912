// footer
.footer {
	margin-top: 8vh;
	text-align: center;

	a {
		text-decoration: none;

		&:hover {
			color: $black;
		}
	}

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// logo
	.logo {
		width: 46px;
		margin: 0 auto 15px auto;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}


	// footer-logolink
	.footer-logolink {
		.list {
			justify-content: center;

			.list-item {
				margin: 0 7.5px;

				.link {
					display: block;
					color: $black;
					font-size: 18px;

					&:hover {
						color: $red;
					}
				}
			}
		}
	}

	// copyright
	.copyright {
		padding: 8vh 0 4vh 0;
		color: $black;
		font-size: 14px;

		ul {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 0 15px;

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						color: inherit;
						text-decoration: underline;
					}
				}
			}
		}
	}

}

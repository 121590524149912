// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 4vh 0;
	background: $cream;

	.wysiwyg {
		font-size: 20px;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}
	}

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		.subtitle {
			h5 {
				margin-bottom: 0;
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 30px;
				display: flex;
				align-items: center;
			}

			.icon {
				margin-right: 10px;

				i {
					color: $red-light;
					font-size: 20px;
					font-weight: 400;
				}
			}

			.label {
				font-size: 14px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.accommodation-detail {
	.lead-section {
		padding-bottom: 20px;

		.container-one-column {
			.container-holder {
				max-width: none;
				margin: 0 -15px;
				text-align: left;
			}
		}

		.info-nav {
			margin-bottom: 0;

			.back-to-overview-link {
				display: none;
			}

			.scroll-to-content {
				@include media-breakpoint-up(lg) {
					position: absolute;
					top: 15px;
					right: 15px;
				}
			}
		}

		.info {
			@include media-breakpoint-down(md) {
				order: -1;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 260px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

&.accommodation-detail {
	.content-section {

		.container-two-columns {
			.container-holder {
				max-width: 780px;

				@include media-breakpoint-up(lg) {
					margin: 0 auto;
				}
			}
		}

		// heading
		.heading {
			margin-bottom: 1rem;
			padding: 0 15px;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		// accordion
		.accordion {
			max-width: 780px;
			margin-top: 30px;
			border-top: 1px solid $cream;

			@include media-breakpoint-up(lg) {
				margin-left: auto;
				margin-right: auto;
			}

			.card {
				border: none;
				border-bottom: 1px solid $cream;
				background: none;

				.card-header {
					padding: 0;
					border: none;
					background: none;

					.card-link {
						position: relative;
						display: flex;
						align-items: center;
						width: 100%;
						padding: 15px 30px 15px 0;
						border: none;
						background: none;
						@include font-size($h2-font-size);
						font-weight: 700;
						font-family: $font-family-secondary;

						@include media-breakpoint-up(xl) {
							font-size: 30px;
						}
						&::after {
							content: '\f00d';
							position: absolute;
							right: 0;
							font-family: $font-awesome;
							color: $red;
							font-size: 20px;
							font-weight: 900;
						}
						&.collapsed {
							&::after {
								content: '\f067';
							}
						}
					}
				}

				.card-body {
					padding: 0;
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		&.grid {
			margin-top: 15px;
			margin-bottom: 15px;
		}

		&.slider {
			.owl-stage-outer {
				margin-left: -15px;
				margin-right: -15px;
				padding: 15px;

				.owl-stage {
					@include media-breakpoint-down(lg) {
						padding-left: 0 !important;
					}
				}
			}

			.owl-nav,
			.owl-dots {
				display: none;
			}
		}
	}

	div.link {
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 20px;
			right: 15px;
		}
	}

}


// square-collection-section
// =========================================================================
.square-collection-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background: $cream;

	// usps
	.usps {
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;

			li {
				margin: 10px 30px;
				font-size: 22px;
				font-weight: 300;
				font-style: italic;
				font-family: $font-family-secondary;

				&::before {
					font-size: 20px;
					font-style: normal;
				}
			}
		}
	}

	// intro-text
	.intro-text {
		font-size: 20px;
		text-align: center;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}

		.container-holder {
			max-width: 750px;
			margin: 45px auto;
			text-align: center;
		}
	}

	// collection
	.collection {

		// grid
		&.grid {
			.grid-items {
				margin: 0 -7.5px -15px -7.5px;

				.item {
					margin-bottom: 15px;
					padding-left: 7.5px;
					padding-right: 7.5px;
					@include make-col(6);

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}
			}
		}

		.card {
			border-radius: 5px;
			border: none;

			.card-img-overlay {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				border-radius: 0;
				padding: 10px;
				background: linear-gradient(180deg, rgba($black, 0) 35%, rgba($black, 0.7) 100%);

				.card-caption {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					flex: 0;
					margin-top: auto;
					text-shadow: none;

					.card-title {
						margin-bottom: 0;
						font-weight: 700;

						&:hover {
							color: $white;
						}
					}
				}

				.card-buttons {
					margin-top: 0;

					.card-btn {
						display: inline-block;
						border: none;
						background: none;
						font-weight: 600;

						&::after {
							content: "\f101";
							margin-left: 5px;
							font-size: 14px;
							font-weight: 500;
							line-height: 1;
							font-family: $font-awesome;
						}

						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}

	// link
	div.link {
		margin-top: 45px;
		text-align: center;

		a {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}


// content-block-section
// =========================================================================
.content-block-section {
	margin: 6vh 0;

	.container-holder {
		align-items: center;
		margin: 0;
		background: $cream;
		border-radius: 6px;
		overflow: hidden;

		.column {
			margin-bottom: 0;

			&.one,
			&.two {
				padding: 0 !important;

			}

			&.wide {
				@include media-breakpoint-up(md) {
					@include make-col(7);
				}
			}

			&.narrow {
				@include media-breakpoint-up(md) {
					@include make-col(5);
				}

				.wysiwyg {
					padding: 15px;

					@include media-breakpoint-up(sm) {
						padding: 40px;
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	background: $cream;

	.wysiwyg {
		max-width: 750px;
		margin: 4vh auto;
		font-size: 20px;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}
	}

	.gallery-horizontal {
		.gallery-item {
			@include make-col(12);

			.gallery-link {
				border-radius: 6px
			}

			&.large-item {
				@include media-breakpoint-down(sm) {
					@include make-col(12);
				}

				@include make-col(6);

				@include media-breakpoint-up(md) {
					@include make-col(9);
				}
			}
		}

		.gallery-thumbs {
			@include media-breakpoint-down(sm) {
				@include make-col(12);
			}

			@include make-col(6);

			@include media-breakpoint-up(md) {
				@include make-col(3);
			}

			.gallery-item {
				@include media-breakpoint-down(md) {
					@include make-col(6);
				}

				@include media-breakpoint-down(sm) {
					@include make-col(4);
				}

				@include media-breakpoint-only(md) {
					@include make-col(12);
				}
			}
		}
	}

}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background: $cream;

	h2 {
		margin-bottom: 30px;
	}
}

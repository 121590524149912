// review-create
.review-create {
  .review-button {
    margin-bottom: 1rem;
  }

  .review-form {
    form {
      padding: 15px;

      @include media-breakpoint-up(xl) {
        padding: 30px;
      }

      border: 1px solid $border-color;
      border-radius: $border-radius;

      .rating {
        color: $primary;
        font-size: 24px;
      }
    }
  }
}

// reviews-overview
.reviews-overview {
  .item {
    margin-top: 30px;

    .review {
      padding: 15px;
      background-color: $white;
      box-shadow: $box-shadow-sm;
      border-radius: $border-radius;

      .rating {
        margin: 15px 0;
        color: $primary;
        font-size: 24px;

        i {
          margin-right: 2px;
        }
      }

      .reviewer {
        font-size: 0.9rem;
      }

      .date {
        font-size: 0.8rem;
      }
    }
  }
}

// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $cream;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container-fluid {
		.container-holder {
			@include media-breakpoint-up(xl) {
				min-height: 100px;
			}

			>.column {
				align-items: center;
				justify-content: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(xs) {
			margin-right: auto;
		}

		.navbar-toggler {
			width: 40px;
			height: 40px;
			margin: 0 15px 0 -10px;
			padding: 0;
			border: none;
			color: $black;
			font-size: 24px;
			transition: color 0.5s;

			i {
				line-height: 40px;
				font-weight: 400;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin: 15px auto;
		transition: width 0.5s;

		@include media-breakpoint-up(xl) {
			position: absolute;
			width: 250px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			width: 140px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-bottom: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;
						font-size: 15px;

						&:hover {
							color: $red;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-family: $font-awesome;
								border: none;
							}
						}
					}

					&.active {
						>.nav-link {
							font-weight: 700;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-link {
							color: $black;
							font-size: 14px;

							&:hover {
								color: $red;
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {

				>.nav-item {

					>.nav-link {
						padding-left: 15px !important;
						padding-right: 15px !important;
						color: $black;
						font-size: 15px;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							font-weight: 700;
						}
					}
				}

				.nav-item {
					&.show {

						>.nav-link {
							background: $white;
							border-radius: 3px 3px 0 0;
							color: $black;
						}
					}

					.dropdown-toggle {
						&::after {
							content: '\f107';
							font-family: $font-awesome;
							border: none;
							margin-left: 5px;
							vertical-align: middle;
						}
					}

					.dropdown-menu {
						z-index: -1;
						min-width: 220px;
						margin-top: 0;
						padding: 15px;
						border-radius: 0 3px 3px 3px;
						border: none;
						left: 0 !important;
						transform: none !important;
						box-shadow: $shadow;

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $red;
								}
							}

							&.active {
								.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						img {
							width: 21px;
							height: 21px;
						}
					}
				}
			}
		}
	}

}

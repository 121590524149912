// mini-sab
.mini-sab {

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
		}
	}
}

&.home {
	.mini-sab {
		padding: 20px 0;
		background: $cream;

	}
}

&:not(.home) {
	.mini-sab {
		@include media-breakpoint-down(lg) {
			padding: 20px 0;
			background: $cream;
		}

		.container {
			.container-holder {

				// above eyecatcher
				@include media-breakpoint-up(xl) {
					position: absolute;
					left: auto;
					right: auto;
					bottom: 0;
					margin-bottom: 30px;
				}
			}
		}
	}
}

// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 300px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 600px;
	@include media-breakpoint-up(sm) {
		height: 75vh;
	}

}


// eyecatcher
.eyecatcher {
	background: $cream;

	.owl-carousel {
		border-radius: 6px;
		overflow: hidden;

		.item {
			.owl-container {
				.owl-caption {
					text-shadow: none;
					.owl-title {
						margin-bottom: 5px;
						@include font-size($h1-font-size);
					}

					.owl-subtitle {
						margin-top: 0;
						@include font-size($h3-font-size);
					}
				}
			}
		}
	}

	// owl
	.owl-dots,
	.owl-nav {
		display: none;
	}
}

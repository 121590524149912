header {
	.page-block.site-switcher {
		margin: .5rem 0;

		> .navbar-nav {
			justify-content: center;
		}
	}

	.page-block.searchbox {
		width: 100%;
		margin: .5rem 0;
		text-align: center;
		order: 6;

		form {
			position: relative;

			input {
				width: 100%;;
				padding-left: 28px;
			}

			&::before {
				display: block;
				content: "\f002";
				color: $red-light;
				font-size: 14px;
				font-weight: 500;
				line-height: 1;
				font-family: $font-awesome;
				transition: 0.5s;
				position: absolute;
				left: 12px;
				top: 10px;
				bottom: 0;
				height: 14px;
			}
		}

		@media screen and (min-width: 768px) {
			order: unset;
			width: auto;
			margin: 0 1rem 0 0;
		}
	}
}

&.search {
	.search_term {
		display: inline-block;
		margin-bottom: 1rem;
		margin-left: 1rem;
	}

	.container-holder .column.default {
		flex-flow: row wrap;

		> .item {
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}
